// NODE MODULES
import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';

// COMPONENTS
import PhotoGallery from '../components/Gallery';


const OfertaSpecjalna = (props) => {

  const data = useStaticQuery(graphql` 
    query {
        desktopImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11.jpg" }) {
            childImageSharp {
                fluid(quality: 100){
                ...GatsbyImageSharpFluid
                }
            }   
        }
        mobileImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11-mobile.jpg" }) {
            childImageSharp {
                fluid(quality: 50){
                ...GatsbyImageSharpFluid
                }
            }   
        }
    }
  `);
const sources = [
  data.mobileImage.childImageSharp.fluid,
  {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
  },
];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area" id="section2">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">SONDERANGEBOTE</h1>
          <div className="content-container">
            <p>Wir laden Sie herzlich zu uns ein. Sie können hier Ostern, Weihnachten, Silvester und andere schöne Wochenenden und Tage verbringen.</p>
            <p>Für solche Angelegenheiten schmücken wir speziell unsere Wohnungen.</p>
            <p>Wir bereiten auch für Sie Frühstücke, einen Heiligen Abend, ein Silvestermenü und andere zu. Wir bestellen Catering zum Appartement.</p>
            <p>Wir schlagen Ihnen auch Voucher als Geschenk für Ihre Vertraute vor.</p>
          </div>
        </section>
      </Fade>
      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='ofertySpecjalne' />
          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default OfertaSpecjalna
